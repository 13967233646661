// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialState = () => {
  const localStorageItem = window.localStorage.getItem('cart')
  if (localStorageItem) {
    let localStorageJson = JSON.parse(localStorageItem)

    if (localStorageJson.access === undefined) {
      localStorageJson.access = 'public'
    }
    return localStorageJson
  }
  return {
    elements: [],
    type: 'ako',
    access: 'public',
    stake: 2.00,
    odd: 1.00,
    potentialWin: 2.00
  }
}

const reOrderElements = (arr) => {
  return arr.sort((a, b) => new Date(a.event.date) - new Date(b.event.date))
}
export const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState(),
  reducers: {
    handleChangeAccess: (state, action) => {
      state.access = action.payload
      localStorage.setItem("cart", JSON.stringify(state))
    },
    handleChangeType: (state, action) => {
      state.type = action.payload
      localStorage.setItem("cart", JSON.stringify(state))
    },
    handleChangeSingleStake: (state, action) => {
      const idx = state.elements.findIndex(x => x.bet.oddId === action.payload.id)
      state.elements[idx].stake = action.payload.value
      state.elements[idx].potentialSingleWin = action.payload.value * state.elements[idx].bet.value
      state.potentialWin = state.stake * state.odd
      localStorage.setItem("cart", JSON.stringify(state))
    },
    handleAddToCart: (state, action) => {
      state.elements.push(action.payload)
      state.potentialWin = state.stake * state.odd
      state.elements = reOrderElements(state.elements)
      localStorage.setItem("cart", JSON.stringify(state))
    },
    handleRemoveFromCart: (state, action) => {
      state.elements = state.elements.filter(x => x.bet.oddId !== action.payload)
      state.potentialWin = state.stake * state.odd
      state.elements = reOrderElements(state.elements)
      localStorage.setItem("cart", JSON.stringify(state))
    },
    handleChangeStake: (state, action) => {
      const idx = state.elements.findIndex(x => x.bet.oddId === action.payload.id)
      state.elements[idx].bet.value = action.payload.value
      state.elements[idx].potentialSingleWin = action.payload.value * state.elements[idx].stake ?? 1.0
      state.potentialWin = state.stake * state.odd
      localStorage.setItem("cart", JSON.stringify(state))
    },
    handleUpdateStake: (state, action) => {
      state.stake = action.payload
      state.potentialWin = state.stake * state.odd
      localStorage.setItem("cart", JSON.stringify(state))
    },
    handleUpdateOdd: (state, action) => {
      state.odd = action.payload
      state.potentialWin = state.stake * state.odd
      localStorage.setItem("cart", JSON.stringify(state))
    },
    // eslint-disable-next-line no-unused-vars
    handleCartClear: (state, action) => {
      state.elements = []
      localStorage.removeItem("cart")
    }
  }
})

export const {
  handleChangeType,
  handleChangeSingleStake,
  handleAddToCart,
  handleRemoveFromCart,
  handleChangeStake,
  handleUpdateStake,
  handleUpdateOdd,
  handleCartClear,
  handleChangeAccess
} = cartSlice.actions

export default cartSlice.reducer
