// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import useJwt from "../auth/jwt/useJwt"

export const initialTypes = () => {
  const item = window.localStorage.getItem('types')
  if (item === "undefined") {
    return []
  }
  return item ? JSON.parse(item) : []
}

export const updateTypes = createAsyncThunk('api/getTypes', async (lang = null) => {
  const response = await useJwt.getTypes(lang)
  return response.data.data
})

export const typeSlice = createSlice({
    name: 'types',
    initialState: {
        types: initialTypes()
    },
  extraReducers: builder => {
    builder
      .addCase(updateTypes.fulfilled, (state, action) => {
        state.types = action.payload
        localStorage.setItem('types', JSON.stringify(action.payload))
        let date = new Date()
        date.setUTCHours(date.getUTCHours() + 24)
        localStorage.setItem('nextCheckTypes', date)
      })
  }
})

export default typeSlice.reducer
