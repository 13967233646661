import { trans } from "../../configs/i18n"
import { getUserData } from "../Utils"
import availableBookmakersFile from '../../availableBookmakers.json'

export const profileNameHelper = (name, additionalText = '') => {
    return name === 'general' ? trans("profiles.all") : `${additionalText}${name}`
}

export const isDisplayWithTax = () => {
    const user = getUserData()?.user
    return (user && user.displayViewWithTax)
}

export const isOwnPage = (id, userId) => id === userId

export const geoLocalizationCountryCode = () => {
    const data = JSON.parse(localStorage.getItem('geoLocalization'))
    if (data) {
        return data.countryCode
    }
    return "PL"
}

export const isBookmakerAvailableInCountry = (bookmakerName) => {
    const countryCode = geoLocalizationCountryCode()
    const countriesArray = availableBookmakersFile.exceptionsByCountryCode
    if (countriesArray[countryCode] !== undefined) {
        const bookmakers = countriesArray[countryCode]
        const isAva = bookmakers.filter((b) => {
            return b.toLowerCase() === bookmakerName.toLowerCase()
        })
        return isAva.length > 0
    }
    return true
}

export const getBookmakerName = (bookmakerName) => {
    if (isBookmakerAvailableInCountry(bookmakerName)) {
        return bookmakerName
    }
    return trans('feed_bookmaker.notAvailableInCountry')
}

export const isCheckGeoLocalization = () => {
    const data = JSON.parse(localStorage.getItem('geoLocalization'))
    if (data) {
        const actualDate = new Date().getTime()
        const date = new Date(data.nextCheckLocalization + 'Z').getTime()

        if (date >= actualDate) {
            return false
        }
    }
    return true
}

export const isCheckTypes = () => {
    const data = localStorage.getItem('nextCheckTypes')
    const types = localStorage.getItem('types')

    if (data !== undefined && types !== "undefined") {
        const actualDate = new Date()
        const date = new Date(data)

        if (date >= actualDate) {
            return false
        }
    }
    return true
}