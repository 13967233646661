// ** Auth Endpoints
export default {
  loginEndpoint: '/login',
  registerEndpoint: '/register',
  refreshEndpoint: '/token/refresh',
  logoutEndpoint: '/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'refreshToken',


  ////
  getOrganisationEndpoint: '/martial-arts/organisation',
  getPlayerEndpoint: '/martial-arts/player',
  getTypesEndpoint: '/form/type',

  postOrganisationEndpoint: '/martial-arts/organisation/add',
  postPlayerEndpoint: '/martial-arts/player/add',

  getFormMartialArtsEventEndpoint: '/martial-arts/form/',
  postFormMartialArtsEventEndpoint: '/martial-arts/form/',
  getMartialArtsEventsEndpoint: '/martial-arts/events',
  searchMartialArtsEventsEndpoint: '/martial-arts/events/search',

  //data
  getAllTypesEndpoint: '/types',
  postTypeLangEndpoint: '/type'


}
