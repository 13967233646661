import {Ability} from '@casl/ability'
import {initialAbility} from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('user'))

if (userData && userData.user.roles !== undefined) {
  const roleAdmin = userData.user.roles.filter((role) => { return role === "ROLE_ADMIN" })
  if (roleAdmin.length > 0) {
    initialAbility.push({
      action: "manage",
      subject: 'all'
    })
  }
}

export default new Ability(initialAbility)
