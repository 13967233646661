import React from "react"
import toast, { Toaster } from "react-hot-toast"
import { trans } from "../../configs/i18n"

export const Notifications = () => <Toaster
    position="top-center"
    reverseOrder={false}
    gutter={8}
    containerClassName=""
    containerStyle={{}}
    toastOptions={{
        // Define default options

        className: '',
        duration: 5000,
        style: {
            color: '#fff'
        },
        success: {
            duration: 3000,
            style: {
                border: '1px solid #2ecc71',
                padding: '16px',
                background: '#2ecc71',
                zIndex: 1000
            },
            iconTheme: {
                primary: '#16a085'
            }
        },
        error: {
            duration: 3000,
            style: {
                border: '1px solid #e74c3c',
                padding: '16px',
                background: '#e74c3c'
            },
            iconTheme: {
                primary: '#c0392b'
            }
        }
    }
    }
/>


export const ToastSuccess = (message, args = undefined) => toast.success(trans(message), args)
export const ToastError = (message) => toast.error(trans(message))

export const ToastCustom = (body, args = undefined) => {
    toast(() => (
        <span>
            {body}
        </span>
    ), args)
}
