// ** Redux Imports
import {createSlice} from '@reduxjs/toolkit'

export const initialState = () => {
  const item = window.localStorage.getItem('globalNotes')
  let localStorageJson = JSON.parse(item)

  return {
    globalNotes: localStorageJson ?? []
  }
}
export const typeSlice = createSlice({
  name: 'globalNotes',
  initialState: initialState(),
  reducers: {
    updateGlobalNotes: (state, action) => {
      state.globalNotes = action.payload
      localStorage.setItem("globalNotes", JSON.stringify(action.payload))
    },
    readGlobalNote: (state, action) => {
      state.globalNotes = state.globalNotes.filter(e => e.id !== action.payload)
      localStorage.setItem("globalNotes", JSON.stringify(state.globalNotes))
    }
  }
})

export const {
  updateGlobalNotes,
  readGlobalNote
} = typeSlice.actions

export default typeSlice.reducer
