// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const initialBookmakers = () => {
  const item = window.localStorage.getItem('choicesBookmakers')
  if (item) {
    return JSON.parse(item).bookmakers ?? []
  }
  return []
}

export const bookmakerSlice = createSlice({
  name: 'choicesBookmakers',
  initialState: {
    bookmakers: initialBookmakers()
  },
  reducers: {
    handleSaveBookmaker: (state, action) => {
      const bookmakerIndex = state.bookmakers.findIndex(x => x === action.payload)
      if (bookmakerIndex !== -1  || action.payload === '') {
        if (bookmakerIndex !== 0) {
          const bookmaker = state.bookmakers.splice(bookmakerIndex, 1)[0]
          state.bookmakers.unshift(bookmaker)
          localStorage.setItem("choicesBookmakers", JSON.stringify(state))
        }
        return
      }
      state.bookmakers.unshift(action.payload)
      localStorage.setItem("choicesBookmakers", JSON.stringify(state))
    }
  }
})

export const {
  handleSaveBookmaker
} = bookmakerSlice.actions

export default bookmakerSlice.reducer
