// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import useJwt from "../../../auth/jwt/useJwt"

export const getChatRooms = createAsyncThunk('api/getChatRooms', async () => {
  const response = await useJwt.getChatRooms()
  return response.data.data
})

export const selectChat = createAsyncThunk('api/getChats', async (room, {  }) => {
  const response = await useJwt.getChats(room.id)

  return {
    chats: response.data.data,
    selectedRoom: room
  }
})

const reOrderChats = (arr) => {
  return arr.sort((a, b) => new Date(b.lastMessage.createdAtFormatted) - new Date(a.lastMessage.createdAtFormatted))
}

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    rooms: [],
    selectedRoom: {}
  },
  reducers: {
    sendTextMessage: (state, action) => {
      state.chats.push(action.payload)
    },
    selectRoom: (state, action) => {
      // state.chats = []
      state.selectedRoom = action.payload
    },
    addNewMessages: (state, action) => {
      state.chats = state.chats.concat(action.payload)
    },
    addOldMessages: (state, action) => {
      const oldMessage = []
      action.payload.forEach(item => {
        const isChatExist = state.chats.find(x => x.id === item.id)
        if (!isChatExist) {
          oldMessage.push(item)
        }
      })
      state.chats = oldMessage.concat(state.chats)
    },
    addNewMessage: (state, action) => {
      const isChatExist = state.chats.find(x => x.id === action.payload.id)
      if (!isChatExist) {
        state.chats.push(action.payload)
      }
    },
    updateChatMessage: (state, action) => {

      const idx = state.chats.findIndex(x => x.id === action.payload.id)
      if (idx !== -1) {
        state.chats[idx] = {
          ...state.chats[idx],
          ...action.payload.data
        }
      }
    },
    updateChatRooms: (state, action) => {
      const idx = state.rooms.findIndex(x => x.id === action.payload.id)
      if (idx !== -1) {
        state.rooms[idx] = action.payload.data
        state.rooms = reOrderChats(state.rooms)
      }
    },
    clearChats: (state) => {
      // state.chats = []
      state.chats = []
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getChatRooms.fulfilled, (state, action) => {
        state.rooms = action.payload
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedRoom = action.payload.selectedRoom
        state.chats = action.payload.chats
      })
  }
})

export const {
  sendTextMessage,
  selectRoom,
  addNewMessages,
  addOldMessages,
  addNewMessage,
  updateChatRooms,
  updateChatMessage,
  clearChats
} = appChatSlice.actions
export default appChatSlice.reducer
