// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

export const initialUser = () => {
    const item = window.localStorage.getItem('user')
    return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        user: initialUser()
    },
    reducers: {
        handleLogin: (state, action) => {
            state.user = action.payload
            state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
            state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
            localStorage.setItem('user', JSON.stringify(action.payload))
            localStorage.setItem(config.storageTokenKeyName, action.payload[config.storageTokenKeyName])
            localStorage.setItem(config.storageRefreshTokenKeyName, action.payload[config.storageRefreshTokenKeyName])
            const sentryUser = action.payload.user
            Sentry.setUser({
                id: sentryUser.id,
                email: sentryUser.email
            })
        },
        handleLogout: state => {
            state.user = {}
            state[config.storageTokenKeyName] = null
            state[config.storageRefreshTokenKeyName] = null
            // ** Remove user, accessToken & refreshToken from localStorage
            Sentry.setUser(null)
            localStorage.removeItem('user')
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
        },
        handleChangeUserData: (state, action) => {
            state.user = action.payload
            localStorage.setItem('user', JSON.stringify(action.payload))
        }
    }
})

export const {
    handleLogin,
    handleLogout,
    handleChangeUserData
} = authSlice.actions

export default authSlice.reducer
