// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const initialState = () => {
  const item = window.localStorage.getItem('oddsType')
  let localStorageJson = JSON.parse(item)

  return {
    type: localStorageJson?.type ?? 'decimal',
    isUserChoice: localStorageJson?.isUserChoice ?? false
  }
}
export const typeSlice = createSlice({
    name: 'oddsType',
    initialState: initialState(),
    reducers: {
        updateOddsType: (state, action) => {
          if (state.type === action.payload) {
            return
          }
          state.type = action.payload
          state.isUserChoice = true
          localStorage.setItem("oddsType", JSON.stringify(state))
        },
      updateOddsTypeByGeoLocalization: (state, action) => {
        if (state.isUserChoice) {
          return
        }
        state.type = action.payload
        state.isUserChoice = false
        localStorage.setItem("oddsType", JSON.stringify(state))
      }
}
})

export const {
  updateOddsType,
  updateOddsTypeByGeoLocalization
} = typeSlice.actions

export default typeSlice.reducer
