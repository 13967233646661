// ** I18n Imports

import axios from "axios"
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import TimeAgo from 'javascript-time-ago'

import pl from 'javascript-time-ago/locale/pl.json'
import en from 'javascript-time-ago/locale/en.json'
import sr from 'javascript-time-ago/locale/sr-Latn.json'
import es from 'javascript-time-ago/locale/es.json'

import { isUserLoggedIn, getUserData } from '@utils'

let lang = window.localStorage.getItem('i18nextLng') ?? undefined

TimeAgo.addLocale(pl)
TimeAgo.addLocale(en)
TimeAgo.addLocale(sr)
TimeAgo.addLocale(es)


if (isUserLoggedIn() !== null) {
  const user = getUserData().user
  lang = user.lang.code
  if (lang === 'sr') {
    TimeAgo.setDefaultLocale('sr-Latn')
  } else {
    TimeAgo.setDefaultLocale(lang)
  }
} else {
  if (lang === 'sr') {
    TimeAgo.setDefaultLocale('sr-Latn')
  }
}

async function detectLanguage() {
  const defaultLang = 'en'
  let lang = defaultLang

  if (isUserLoggedIn()) {
    const user = getUserData().user
    lang = user.lang.code || defaultLang
    return lang
  }

  const cachedLang = window.localStorage.getItem('detectedLang')
  const lastCheck = window.localStorage.getItem('lastLangCheck')
  const now = Date.now()
  const sixHours = 6 * 60 * 60 * 1000

  if (cachedLang && lastCheck && now - lastCheck < sixHours) {
    return cachedLang
  }

  try {
    const response = await axios.get('https://ipapi.co/json/')
    const countryCode = response.data.country_code

    if (countryCode === 'RS') {
      lang = 'sr'
    } else if (countryCode === 'PL') {
      lang = 'pl'
    }  else if (countryCode === 'ES') {
      lang = 'es'
    } else {
      lang = defaultLang // Domyślny język
    }

    window.localStorage.setItem('detectedLang', lang)
    window.localStorage.setItem('lastLangCheck', now.toString())
    console.log('Detected and cached language:', lang)
  } catch (error) {
    console.error('Error detecting location:', error)
    lang = cachedLang || defaultLang
  }

  return lang
}

(async () => {
  const detectedLang = await detectLanguage()

  i18n
    // Enables the i18next backend
    .use(Backend)
    // Enable automatic language detection
    .use(LanguageDetector)
    // Enables the hook initialization module
    .use(initReactI18next)
    .init({
      lng: detectedLang,
      detection: {
        order: ['navigator', 'localStorage', 'cookie', 'htmlTag'],
        caches: ['localStorage']
      },
      backend: {
        loadPath: '/assets/data/locales/{{lng}}.json'
      },
      fallbackLng: {
        sr: ['sr'],
        'sr-Latn': ['sr'],
        'en-US': ['en'],
        'es-ES': ['es'],
        'pl-PL': ['pl'],
        default: ['en']
      },
      debug: false,
      keySeparator: false,
      react: {
        useSuspense: false
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ','
      }
    })
})()

export default i18n

export function trans(key, opts) {
  return i18n.t(key, {
    ...opts,
    postProcess: `reactPostprocessor`
  })
}